// Inspired by this comment:
// https://stackoverflow.com/posts/59741870/revisions
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    allowedDeltaPx: { type: Number, default: 6 }
  }

  connect() {
    this.#reset()
  }

  disconnect() {
    this.#reset()
  }

  startPointerAction({ pageX, pageY }) {
    this.startX = pageX
    this.startY = pageY
  }

  endPointerAction({ target, pageX, pageY }) {
    const diffX = Math.abs(pageX - this.startX)
    const diffY = Math.abs(pageY - this.startY)

    const isClick = diffX < this.allowedDeltaPxValue && diffY < this.allowedDeltaPxValue

    if (isClick && target) {
      this.dispatch("click", { target })
    }
  }

  // Private
  #reset() {
    this.startX = null
    this.startY = null
  }
}
